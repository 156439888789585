<template>
  <div
      class="tab-pane fade show active"
      :id="`kt_upgrade_plan_${index}`"
  >
    <!--begin::Heading-->
    <div class="pb-5">
      <h1 class="fw-bolder font-weight-bold plan-name">
      <b> {{plan.Name}}</b>
      </h1>

      <!-- <div class="text-gray-400 fw-bold">
        {{ plan.Description }}
      </div> -->
    </div>
    <!--end::Heading-->

    <!--begin::Body-->
    <div v-for="(feature,i) in planDetail" v-bind:key="i">

      <div
          :class="[i !== plan.features - 1 && 'mb-7']"
          class="d-flex align-items-center"
      >
        <div v-if="feature.pre != false && feature.pre != true " >{{'Precentage'}} &nbsp;</div>

        <template v-if="feature.pre">
          <span
              class="fw-bold fs-5 text-gray-700 flex-grow-1"
          >
            {{ feature.text }}
          </span>
          <span
          class="svg-icon svg-icon-1 svg-icon-success"
          >
            <inline-svg
                src="media/icons/duotune/general/gen043.svg"
            />
          </span>
          <PlanCheckIcon v-if="feature.pre ==true" />
          <div v-if="feature.pre != false && feature.pre != true " >{{feature.pre}}</div>
        </template>
        <template v-else>
          <span
              class="fw-bold fs-5 text-gray-400 flex-grow-1"
          >
            {{ feature.text }}
          </span>
          <span class="svg-icon svg-icon-1">
            <inline-svg
                src="media/icons/duotune/general/gen040.svg"
            />
          </span>
          <PlanUnCheckIcon v-if="feature.pre == false"/>
          <div v-if="feature.pre != false && feature.pre != true " >{{feature.pre}}</div>

        </template>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import PlanCheckIcon from "@/pages/Dashboard/plan_components/PlanCheckIcon";
import PlanUnCheckIcon from "@/pages/Dashboard/plan_components/PlanUnCheckIcon";
export default {
  name: "PlanDetail",
  components: {
    PlanCheckIcon,PlanUnCheckIcon
  },
  props: ['plan','planDetail'],
}
</script>

<style scoped>
.tab-content.rounded.h-100.bg-light.p-10 {
  background: lightgray !important;

}
div#kt_upgrade_plan_undefined {
  color: #b7b7b7;
}

.plan-name {
  color: #fff;
}
</style>