<template>
  <!--begin::Container-->
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-sm-12">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-body">

              <div class="mb-13 text-center">
							<h1 class="mb-3" style="font-weight: 600;color: #fff;">Upgrade your Plan</h1>
							<div class="text-muted fw-bold fs-5">get full access to our automated trading tools, for any questions 
							<a href="https://discord.com/invite/XYPM7EbXqk" class="link-primary fw-bolder"> Open a ticket on Discord</a>.</div>
						</div>


            <div class="d-flex flex-column">
              <!--begin::Row-->
              <!--begin::Nav group-->
            <div
              class="nav-group nav-group-outline mx-auto plan-switch"
              data-kt-buttons="true"
            >
              <button
                @click="current = 'month'"
                :class="[current === 'month' && 'active']"
                class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2"
                :style="[current === 'month' ? {'color': 'rgb(255, 255, 255) !important', 'background': '#222831 none repeat scroll 0% 0%', 'margin': '4px'} : {'color' : 'grey'}]"
              >
                Monthly
              </button>
              <button
                @click="current = 'annual'"
                :class="[current === 'annual' && 'active']"
                class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"
                :style="[current === 'annual' ? {'color': 'rgb(255, 255, 255) !important', 'background': '#222831 none repeat scroll 0% 0%', 'margin': '4px'} : {'color' : 'grey'}]"
              >
                Annual
              </button>
            </div>
            <!--end::Nav group-->

              <div class="row mt-10">
                <!--begin::Col-->
                <div class="col-lg-6 mb-10 mb-lg-0">
                  <!--begin::Tabs-->
                  <div class="nav flex-column ">
                    <div v-for="(plan, index) in plans" :key="index">
                      <!--begin::Tab link-->
                      <div style="border:1px solid; border-style:dashed !important;"
                           :style="[plans[selected] === plan ? {'color':'#1DB489', 'background':'#363f4a'} :'']"
                           @click="selected = index"
                           class="nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 checkmark"
                           :class="[
                                       index !== plans.length - 1 && 'mb-6',
                                       plan.default && 'active',
                                       !plan.custom && 'btn-active btn-active-primary',
                                       ]"
                           data-bs-toggle="tab"
                           :data-bs-target="`#kt_upgrade_plan_${index}`"
                      >
                        <!--end::Description-->
                        <div class="d-flex  me-2 ">
                          <!--begin::Radio-->
                          <div
                              class="mt-5 pt-2 form-check form-check-custom form-check-solid form-check-success me-6"
                          >
                            <input
                                style="accent-color: #1DB489"
                                class="form-check-input mt-auto" id="radioGreen1"
                                type="radio"
                                name="plan"
                                :value="plan"
                                :checked="plans[selected] === plan"

                            />
                          </div>
                          <!--end::Radio-->
                          <!--begin::Info-->
                          <div class="flex-grow-1 ml-4" >
                            <h2
                                class="d-flex align-items-center fs-2 fw-bolder flex-wrap"
                            >
                              {{ plan.Name }}

                            </h2>
                            <div class="fw-bold opacity-50">
                              {{ plan.Description }}
                            </div>
                          </div>
                          <!--end::Info-->
                        </div>
                        <!--end::Description-->
                        <!--begin::Price-->
                        <div class="ms-5 ml-auto">

                          <template class="ml-5">
                            <span class="mb-2" v-if="plan.Price">$</span>
                            <span class="fs-3x fw-bolder" style="font-size: 30px; font-weight: 600; color: #fff;">
                               {{current === "month" ? plan.Price === 0 ? "Free" : plan.Price.toFixed(2) : plan.Price === 0 ? "Free" : (12*plan.Price).toFixed(2) }}
                            </span>
                            <span class="fs-7 opacity-50" >/
                              <span data-kt-element="period">{{current === "month" ? 'Monthly': 'Per Year'}}</span>
                                             </span>
                          </template>
                        </div>
                        <!--end::Price-->

                        
                      </div>




                      
                      <!--end::Tab link-->
                    </div>
                  </div>
                  <!--end::Tabs-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-6" v-if="selected != null">
                  <!--begin::Tab content-->
                  <div class="tab-content rounded h-100 bg-light p-10" style="    color:#fff;background: #363f4a !important;">
                    <PlanDetail :plan="plans[selected]" :plan-detail="selectedPlan"/>
                  </div>

                

                  <!--end::Tab content-->
                </div>
                <!--end::Col-->

              </div>

              <!--end::Row-->

              <!-- begin: Pricing-->
              <!--              <div v-for="(plan, i) in plans" :key="plan.Id"-->
              <!--                   :class="i == 1 ? 'col-md-4 col-xxl-3 bg-primary-o-10 my-md-n15 rounded shadow-sm' : 'col-md-4 col-xxl-3 bg-white rounded-left shadow-sm'">-->
              <!--                <div :class="i == 1 ? 'pt-25 pt-md-37 pb-25 pb-md-10 py-md-28 px-4' : 'pt-25 pb-25 pb-md-10 px-4'">-->
              <!--                  <h4 class="mb-15">{{ plan.Name }}</h4>-->
              <!--                  <span v-if="i == 1" class="px-7 py-3 bg-white d-inline-flex flex-center rounded-lg mb-15 bg-white">-->
              <!--                           <span class="pr-2 text-primary opacity-70">$</span>-->
              <!--                           <span-->
              <!--                               class="pr-2 font-size-h1 font-weight-bold text-primary">{{-->
              <!--                               plan.Price.toFixed(2)-->
              <!--                             }}</span>-->
              <!--                           <span class="text-primary opacity-70">/&nbsp;&nbsp;Month</span>-->
              <!--                           </span>-->
              <!--                  <span v-else class="px-7 py-3 d-inline-flex flex-center rounded-lg mb-15 bg-primary-o-10">-->
              <!--                           <span class="pr-2 opacity-70">$</span>-->
              <!--                           <span class="pr-2 font-size-h1 font-weight-bold">{{ plan.Price.toFixed(2) }}</span>-->
              <!--                           <span class="opacity-70">/&nbsp;&nbsp;Month</span>-->
              <!--                           </span>-->
              <!--                  <br>-->
              <!--                  <p class="mb-10 d-flex flex-column" style="font-size: 14px;"-->
              <!--                     :class="{'text-white': i == 1, 'text-dark-50': i != 1}">-->
              <!--                    <span><span class="text-primary font-weight-bold">{{ plan.SignalPercentageToShow }}%</span> of the signals</span>-->
              <!--                    <span>-->
              <!--                                 <span class="svg-icon svg-icon-primary svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"-->
              <!--                                              fill="#000000" fill-rule="nonzero"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 Auto buy-->
              <!--                              </span>-->
              <!--                    <span>-->
              <!--                                 <span class="svg-icon svg-icon-primary svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"-->
              <!--                                              fill="#000000" fill-rule="nonzero"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 Stop loss-->
              <!--                              </span>-->
              <!--                    <span>-->
              <!--                                 <span class="svg-icon svg-icon-primary svg-icon-2x">-->

              <!--                                 </span>-->
              <!--                                 Take profit-->
              <!--                              </span>-->
              <!--                    <span>-->
              <!--                                 <span v-if="plan.HasTrailing" class="svg-icon svg-icon-primary svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"-->
              <!--                                              fill="#000000" fill-rule="nonzero"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 <span v-else class="svg-icon svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"-->
              <!--                                              fill="#000000"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 Trailing-->
              <!--                              </span>-->
              <!--                    <span>-->
              <!--                                 <span v-if="plan.CanSetBudgetLimit"-->
              <!--                                       class="svg-icon svg-icon-primary svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"-->
              <!--                                              fill="#000000" fill-rule="nonzero"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 <span v-else class="svg-icon svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"-->
              <!--                                              fill="#000000"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 Budget limits-->
              <!--                              </span>-->
              <!--                    <span>-->
              <!--                                 <span v-if="plan.CanEnableCompounding"-->
              <!--                                       class="svg-icon svg-icon-primary svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"-->
              <!--                                              fill="#000000" fill-rule="nonzero"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 <span v-else class="svg-icon svg-icon-2x">-->
              <!--                                    <svg xmlns="http://www.w3.org/2000/svg"-->
              <!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"-->
              <!--                                         viewBox="0 0 24 24" version="1.1">-->
              <!--                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
              <!--                                          <rect x="0" y="0" width="24" height="24"/>-->
              <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
              <!--                                          <path-->
              <!--                                              d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"-->
              <!--                                              fill="#000000"/>-->
              <!--                                       </g>-->
              <!--                                    </svg>-->
              <!--                                 </span>-->
              <!--                                 Compounding-->
              <!--                              </span>-->
              <!--                  </p>-->
              <!--                  <button @click="buy(plan)" type="button" v-if="i == 1"-->
              <!--                          class="btn btn-primary text-uppercase font-weight-bolder px-15 py-3">Purchase-->
              <!--                  </button>-->
              <!--                  <button @click="buy(plan)" type="button" v-else-->
              <!--                          class="btn btn-transparent text-uppercase font-weight-bolder px-15 py-3 date-sel-active text-white">-->
              <!--                    Purchase-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <!-- end: Pricing-->
            </div>
            <div class="d-flex mt-12">
              <button v-if="selected != 0" :disabled="!plans[selected]" @click="buy(plans[selected])" type="button"
                      class=" btn btn-primary text-uppercase font-weight-bolder px-15 py-4" style="width:49%;">Purchase
              </button>
            </div>

          </div>
        </div>
        <!--end::Card-->
      </div>


      <div class="col-lg-3 col-sm-12">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-body">
            <div class="mb-13 text-center">
						<h1 class="mb-3" style="font-weight: 600;color: #fff;">Your Plan</h1>
            <div style="float:left; text-align:left; font-size:17px;">
              <div class="fw-bold"> Current Plan: <b style="color:#fff;">{{current_plan.Plan ? current_plan.Plan.Name : 'Unavailable'}}</b> </div>
              <div class="fw-bold"> Expiration: <b style="color:#fff;">{{current_plan.EndTime ? convertDateTime(current_plan.EndTime) : 'Unavailable'}}</b></div>
              <div class="fw-bold"> Price: <b style="color:#fff;">{{current_plan.Plan ? current_plan.Plan.Price : 'Unavailable'}}</b></div>
						</div>
						</div>
          </div>
        </div>
      </div>


    </div>
    <!--end::Dashboard-->
  </div>
  <!--end::Container-->
</template>
<script>
import Maurer from '../../components/Maurer.vue';
import PlanDetail from "@/pages/Dashboard/plan_components/PlanDetail";
import moment from 'moment';

/*
*  {
    "Id": "e6928fba-c979-11ec-a7ba-065f34086000",
    "Name": "Free",
    "Description": "Free Plan",
    "SignalPercentageToShow": 20,
    "HasTrailing": false,
    "CanSetBudgetLimit": false,
    "CanEnableCompounding": false,
    "Price": 0,
    "Transactions": null,
    "CreatedAt": "2022-05-01T18:09:53Z",
    "UpdatedAt": "2022-05-01T18:09:53Z"
  }
*
* */

function planItem(pre, text) {
  return {
    pre,
    text
  }
}

function createPlanDetail({SignalPercentageToShow, HasTrailing, CanSetBudgetLimit, CanEnableCompounding}) {
  let finalPlanDetails = [];
  finalPlanDetails.push(planItem(`${SignalPercentageToShow}%`, "of the signals"));
  finalPlanDetails.push(planItem(true, "Auto buy"));
  finalPlanDetails.push(planItem(true, "Stop loss"));
  finalPlanDetails.push(planItem(true, "Take profit"));
  finalPlanDetails.push(planItem(HasTrailing, " Trailing"));
  finalPlanDetails.push(planItem(CanSetBudgetLimit, "Budget limits"));
  finalPlanDetails.push(planItem(CanEnableCompounding, "Compounding"));
  return finalPlanDetails;
}

export default {
  components: {PlanDetail},
  data() {
    return {
      selected: 0,
      plans: [],
      current: 'month',
      current_plan: [],
      current_time: null,
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    selectedPlan() {
      return createPlanDetail(this.plans[this.selected]);
    }
  },
  methods: {
    buy(plans) {
      var vm = this;
      if(this.current === 'month'){
        Maurer.api.plans.buy(plans, function (d) {
          window.open(d.url);
        }, function (d) {
          Maurer.api.error(d);
        });
      }
      else{
        Maurer.api.plans.buy_annual(plans, function (d) {
          window.open(d.url);
        }, function (d) {
          Maurer.api.error(d);
        });
      }
    },

    init() {
      var vm = this;
      Maurer.api.plans.get(function (d) {
        vm.plans = d.plans;
      }, function (d) {
        Maurer.api.error(d);
      });

      Maurer.api.user.get(function (d) {
        let plan_history =  d.data.PlansHistory;
        vm.getCurrentPlan(plan_history);
      }, function (d) {
        Maurer.api.error(d);
      });
    },

    getCurrentPlan(plan_history) {
      var vm = this;
      Maurer.api.user.get_current_time(function (d) {
        vm.current_time = d.time;
        let date_time = moment(vm.current_time).format();

        for(var i = 0; i < plan_history.length; i++){
          let start_time = moment(plan_history[i].StartTime).format();
          let end_time = moment(plan_history[i].EndTime).format();

          if(moment(date_time).isAfter(start_time) && moment(date_time).isBefore(end_time)){
            vm.current_plan = plan_history[i];
          }
        }
      }, function (d) {
        Maurer.api.error(d);
      });
    },

    convertDateTime(datetime) {
      return moment(datetime).format('YYYY-MM-DD');
    }
  }
}
</script>
<style>
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
}

.card-label {
  color: #ffffff !important;
}
.seleced-div{
  color: #1DB489 !important;
}

.plan-switch {
  border: 1px solid grey; 
  border-radius: 6px;
}
</style>